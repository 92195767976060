import styled from "styled-components";

import imageBg from "../../assets/images/darren.jpg";

export const ContainerNewLogin = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: white;

  z-index: 99;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .leftSide {
    width: 50%;
    height: 100%;
    background-color: white;

    padding: 30px;

    position: relative;

    &::before {
      content: "";

      /* background-image: url(https://i.ibb.co/KwZMSTv/pawel-czerwinski-y-JJp-So-CB5-YE-unsplash.jpg); */
      background-image: url(https://i.ibb.co/zZMg256/darren-richardson-E7-D6-Ohc-AVY-unsplash.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      margin: 30px;

      border-radius: 20px;
    }

    img {
      width: 100%;
      border-radius: 20px;
    }
  }

  @media (max-width: 1120px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .leftSide {
      display: none;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`;
